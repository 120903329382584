import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import cn from "classnames";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { FormEvent, ReactElement, useState } from "react";

import Button from "../../../../../atoms/button/Button";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { Events, track } from "../../../../../utils/analytics";
import { useCreateSubscription } from "../../../application/checkout-use-cases";
import {
  handleConfirmIntentStripeErrors,
  handleCreateSubscriptionErrors,
} from "../../../utils/error-utils/catch-error-handlers";
import * as styles from "./CheckoutForm.module.scss";

interface CheckoutFormProps {
  adoptionClassName?: string;
}

const CheckoutForm = ({ adoptionClassName }: CheckoutFormProps): ReactElement => {
  const stripe = useStripe();
  const elements = useElements();
  const result = useResult();
  const createSubscription = useCreateSubscription();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const handleStripeError = (error: StripeError): void => {
    track(Events.ERROR_OCCURRED, {
      type: error.type,
      code: error.code,
    });

    handleConfirmIntentStripeErrors(error, error.message || t("common.error_message"));
    setErrorMessage(error.message || t("common.error_message"));
  };

  const handleSubscriptionError = (error: Error): void => {
    handleCreateSubscriptionErrors(error, error.message);
    setErrorMessage(t("common.error_message"));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!stripe || !elements || !result) {
      return;
    }

    setIsLoading(true);

    if (!result.isResultValidToCheckout) {
      await navigate(`/results/${result.uuid}/`);

      return;
    }

    track(Events.CLICKED_CTA, { eventSender: "Checkout page contract CTA" });

    try {
      const { error: submitError } = await elements.submit();

      if (submitError) {
        setErrorMessage(submitError.message || t("common.error_message"));

        return;
      }

      const { type, client_secret: clientSecret } = await createSubscription();
      const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

      const { error: confirmIntentError } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${process.env.GATSBY_URL_BASE}/results/${result?.uuid}/checkout_success/`,
        },
      });

      if (confirmIntentError) {
        handleStripeError(confirmIntentError);

        return;
      }
    } catch (error) {
      handleSubscriptionError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReady = (): void => {
    setIsReady(true);
  };

  return (
    <>
      {stripe && elements && (
        <form onSubmit={handleSubmit} className={cn(styles.form, adoptionClassName)}>
          <PaymentElement onReady={handleReady} />
          {isReady && (
            <Button
              adoptionClassName={styles.submit}
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {t("common.cta.contract")}
            </Button>
          )}
          <>{errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}</>
        </form>
      )}
    </>
  );
};

export default CheckoutForm;
