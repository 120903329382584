import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { navigate } from "gatsby";
import { ReactElement, useEffect, useState } from "react";

import PageStepAnimation from "../../../../../atoms/page-step-animation/PageStepAnimation";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { Events } from "../../../../../utils/analytics";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import { convertToSmallestUnit } from "../../../settings/payments";
import { useLoadStripeLibrary } from "../../../utils/hooks/useLoadStripeLibrary";
import { getDefaultStripeElementsOptions } from "../../../utils/stripe";
import CheckoutForm from "../../organisms/checkout-form/CheckoutForm";
import CheckoutSummary from "../../organisms/checkout-summary/CheckoutSummary";
import * as styles from "./Checkout.module.scss";

const Checkout = (): ReactElement => {
  const result = useResult();
  const cart = useShoppingCart();

  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  const hasTrialPeriod = result?.hasPolicyStartDateAfterTomorrow ?? null;
  const getStripeElementsOptions = () => {
    if (!result) {
      return;
    }

    return {
      amount: hasTrialPeriod ? 0 : convertToSmallestUnit(cart.paymentAmount),
      ...getDefaultStripeElementsOptions(result.country),
    };
  };

  useTrackPageViewed(Events.CHECKOUT_VIEWED_BROWSER);
  useLoadStripeLibrary(setStripePromise);

  useEffect(() => {
    if (result && result.isAlreadyPaid) {
      void navigate(`/results/${result.uuid}/welcome/`);
    }
  }, [result]);

  return (
    <>
      {result && stripePromise && (
        <Elements options={getStripeElementsOptions()} stripe={stripePromise}>
          <PageStepAnimation>
            <div className={styles.wrapper}>
              <CheckoutSummary adoptionClassName={styles.summary} />
              <CheckoutForm adoptionClassName={styles.form} />
            </div>
          </PageStepAnimation>
        </Elements>
      )}
    </>
  );
};

export default Checkout;
