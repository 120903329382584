import { useResultUuid } from "../../../organisms/result/use-cases/result-use-cases";
import { createSubscription } from "../data/checkout-repository";
import { usePromotionCode } from "./promotion-code-use-cases";

export const useCreateSubscription = (): (() => Promise<Record<string, string>>) => {
  const resultUuid = useResultUuid();
  const promotionCode = usePromotionCode();
  const promotionCodeId = promotionCode?.id;

  if (!resultUuid) {
    return () => Promise.resolve({});
  }

  return async () => {
    return createSubscription(
      resultUuid,
      JSON.stringify({
        ...(promotionCodeId && { promotion_code_id: promotionCodeId }),
      })
    );
  };
};
