import { fetchApiJson } from "../../../utils/request";

export async function createSubscription(
  resultUuid: string,
  body: string
): Promise<Record<string, string>> {
  return await fetchApiJson(`${process.env.GATSBY_API_BASE}/results/${resultUuid}/subscription/`, {
    body,
    method: "POST",
  });
}
